import _isObject from 'lodash/isObject.js';
import qs from 'querystring';
import 'isomorphic-fetch';
import {
    onInvalidSessionError,
    onUserMismatchError,
    onClientVersionExpiredError,
    reloadBrowser,
    getSessionId,
} from '../actions/session.js';

import { getStore } from '../common/store.js';
import {
    LOGIN_SOURCE_WEBAPP,
    DOCUMENT_VISIBILITY_VISIBLE,
    DOCUMENT_VISIBILITY_HIDDEN,
} from '../common/constants.js';
import { faultTolerantResolve } from '~common/retry.helper';
import { getIsDocumentVisible } from '~common/utils';

export async function checkStatus(response) {
    const store = getStore();

    const currentUserId = store.getState()?.user?.id;
    const requestUserId = response.headers.get('x-request-user');

    const userMismatch =
        currentUserId && requestUserId && currentUserId !== requestUserId;

    if (!userMismatch && response.status >= 200 && response.status < 300) {
        // 205 Reset Content
        if (response.status === 205) {
            reloadBrowser();
        }
        return response;
    } else if (response?.name === 'AbortError') {
        return response.value;
    } else {
        let body, errorType, message;
        if (
            response.headers.get('Content-type')?.includes('application/json')
        ) {
            body = await response.clone().json();
            errorType = body?.name;
            message = body?.message;
        }
        if (response.status > 400 && response.status < 500) {
            switch (errorType) {
                case 'AuthenticationError':
                case 'InvalidSessionError': {
                    await store.dispatch(onInvalidSessionError());
                    break;
                }
                case 'UserMismatchError': {
                    await store.dispatch(
                        onUserMismatchError(store.getState().user)
                    );
                    break;
                }
                case 'ClientVersionExpiredError': {
                    const { mustRefresh, hasHelpsiteFeature } = body;
                    await store.dispatch(
                        onClientVersionExpiredError(
                            mustRefresh,
                            hasHelpsiteFeature
                        )
                    );
                }
            }
        } else if (userMismatch) {
            await store.dispatch(onUserMismatchError(store.getState().user));
        }

        const error = new Error(message || `HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.statusCode = response.status;
        error.name = errorType || error.name;
        error.response = response;
        throw error;
    }
}

export function parseJson(response) {
    if (response.status === 204) {
        return null;
    }
    if (response.status === 205) {
        return false;
    }
    if (response.redirected) {
        return { redirectUrl: response.url };
    }

    return response?.json?.() || response;
}

function transformFetchError(err) {
    const error = /failed to fetch$/i.test(err.message)
        ? new Error('Failed to communicate with server')
        : err;
    //eslint-disable-next-line no-console
    console.error(err, error, `Transform fetch error (${err.message})`);
    throw error;
}

export const commonHeaders = () => ({
    'x-request-user': getStore().getState()?.user?.id,
    'x-client-version': window?.__minuteme_appConfig?.VERSION,
    'x-client-revision': window?.__minuteme_appConfig?.REVISION,
    'x-request-source': LOGIN_SOURCE_WEBAPP,
    'x-document-visible': getIsDocumentVisible()
        ? DOCUMENT_VISIBILITY_VISIBLE
        : DOCUMENT_VISIBILITY_HIDDEN,
});

export const commonHeadersJSON = () => ({
    'Content-Type': 'application/json',
    accept: 'application/json',
});

const retryOptions = {
    retries: 3,
    factor: 2,
    minTimeout: 200,
    maxTimeout: 1000,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line require-await
export async function get(url, params, headers = {}, fetchOptions = {}) {
    if (_isObject(params) && Object.keys(params).length) {
        url = `${url}?${qs.stringify(params)}`;
    }

    const { promise } = faultTolerantResolve(
        `fetchGet-${url}`,
        () =>
            fetch(url, {
                method: 'get',
                headers: {
                    ...commonHeaders(),
                    ...commonHeadersJSON(),
                    ...headers,
                },
                cache: 'no-cache',
                credentials: 'include',
                ...fetchOptions,
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise.then(parseJson);
}

/**
 * Perform an HTTP Get but returning the fetch 'result' to the calling function, rather than the JSON.parse'd body
 */
export function getRaw(url, params, headers = {}) {
    if (_isObject(params) && Object.keys(params).length) {
        url = `${url}?${qs.stringify(params)}`;
    }

    const { promise } = faultTolerantResolve(
        `fetchGet-${url}`,
        () =>
            fetch(url, {
                method: 'get',
                headers: {
                    ...commonHeaders(),
                    ...commonHeadersJSON(),
                    ...headers,
                },
                cache: 'no-cache',
                credentials: 'include',
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise;
}

/**
 * A Simple HTTP GET request is used to bypass CORS.
 * Refer to https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
 */
export function getSimple(url, params, fetchOptions) {
    if (_isObject(params) && Object.keys(params).length) {
        url = `${url}?${qs.stringify(params)}`;
    }

    const { promise } = faultTolerantResolve(
        `fetchGet-${url}`,
        () =>
            fetch(url, {
                method: 'get',
                headers: {
                    accept: 'application/json',
                },
                cache: 'no-cache',
                ...fetchOptions,
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise.then(parseJson);
}

export function deleteRequest(url, headers = {}) {
    const { promise } = faultTolerantResolve(
        `fetchDelete-${url}`,
        () =>
            fetch(url, {
                method: 'delete',
                headers: {
                    ...commonHeaders(),
                    ...commonHeadersJSON(),
                    'x-csrf-token': getStore().getState()?.user?.csrf,
                    ...headers,
                },
                cache: 'no-cache',
                credentials: 'include',
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise.then(parseJson);
}

/**
 * use fetch to PATCH a message to the api. In this case,
 * send a special header to be used by the requesting client to drop any incoming websocket events
 * triggered by that request.
 * @param {string} url
 * @param {object} data
 * @param {object} headers
 */
export const patchSuppressingWS = (url, data, headers = {}) =>
    patch(url, data, {
        'x-ws-suppression-token': getSessionId(),
        ...headers,
    });

export function patch(url, data, headers = {}) {
    const { promise } = faultTolerantResolve(
        `fetchPatch-${url}`,
        () =>
            fetch(url, {
                method: 'PATCH',
                headers: {
                    ...commonHeaders(),
                    ...commonHeadersJSON(),
                    'x-csrf-token': getStore().getState()?.user?.csrf,
                    ...headers,
                },
                cache: 'no-cache',
                credentials: 'include',
                body: JSON.stringify(data),
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise.then(parseJson);
}

/**
 * use fetch to POST a message to the api. In this case,
 * send a special header to be used by the requesting client to drop any incoming websocket events
 * triggered by that request.
 * @param {string} url
 * @param {object} data
 * @param {object} headers
 */
export const postSuppressingWS = (url, data, headers = {}) =>
    post(url, data, {
        'x-ws-suppression-token': getSessionId(),
        ...headers,
    });

export function post(url, data, headers = {}) {
    const { promise } = faultTolerantResolve(
        `fetchPost-${url}`,
        () =>
            fetch(url, {
                method: 'post',
                headers: {
                    ...commonHeaders(),
                    ...commonHeadersJSON(),
                    'x-csrf-token': getStore().getState()?.user?.csrf,
                    ...headers,
                },
                cache: 'no-cache',
                credentials: 'include',
                body: JSON.stringify(data),
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise.then(parseJson);
}

export function postForm(url, formData, headers = {}) {
    const { promise } = faultTolerantResolve(
        `fetchPostForm-${url}`,
        () =>
            fetch(url, {
                method: 'post',
                headers: {
                    ...commonHeaders(),
                    'x-csrf-token': getStore().getState()?.user?.csrf,
                    ...headers,
                },
                cache: 'no-cache',
                credentials: 'include',
                body: formData,
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise.then(parseJson);
}

export function put(url, data, headers = {}) {
    const { promise } = faultTolerantResolve(
        `fetchPut-${url}`,
        () =>
            fetch(url, {
                method: 'put',
                headers: {
                    ...commonHeaders(),
                    ...commonHeadersJSON(),
                    'x-csrf-token': getStore().getState()?.user?.csrf,
                    ...headers,
                },
                cache: 'no-cache',
                credentials: 'include',
                body: JSON.stringify(data),
            })
                .catch(transformFetchError)
                .then(checkStatus),
        retryOptions
    );

    return promise.then(parseJson);
}
